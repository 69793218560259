import { mapState } from "vuex";
import { env } from "md-base-tools/env";
import { Icon, showDialog } from 'vant';
import MDRouter from "@/service/router";
import { FrontGetUserInfo, getProInvitationInfo, AcceptProjectInvitation } from "@/api";
import { goToLogin } from "@/utils/user";
import { mdToast } from "../../utils/tools";
export default {
  components: {
    Icon
  },
  computed: {
    ...mapState({
      userId: state => state.user.userId
    })
  },
  data() {
    return {
      env,
      uid: '',
      // 受邀人id
      pro_id: '',
      temp_id: '',
      default_img: "https://s.moimg.net/new/images/headPic.png",
      isFirst: sessionStorage.getItem('invite_isfirst') == 'NO' ? false : true,
      // 是否是第一次进入
      agreement_status: false,
      // 受邀者信息
      invitee_user_info: {
        nickname: '',
        icon: ''
      },
      // 邀请项目详情
      pro_invitation_info: {
        is_join: false,
        title: ''
      },
      cp_info: {
        icon: '',
        nickname: ''
      }
    };
  },
  async mounted() {
    const {
      uid,
      pid,
      temp_id
    } = this.$route.query;
    if (!uid) return;
    this.uid = uid;
    this.pro_id = pid;
    this.temp_id = temp_id;
    await this.getUserInfo();
    if (!this.isFirst) {
      if (!this.userId) {
        goToLogin();
      } else if (this.userId != this.uid) {
        this.isFirst = true;
        sessionStorage.removeItem('invite_isfirst');
        window.location.reload();
      } else {
        this.getProInfo();
      }
    }
  },
  beforeUnmount() {
    sessionStorage.removeItem('invite_isfirst');
  },
  methods: {
    getUserInfo() {
      FrontGetUserInfo({
        user_id: this.uid
      }).then(res => {
        if (res.status == 0) {
          this.invitee_user_info = res.data;
        }
      });
    },
    // 开启
    open() {
      if (!this.userId) {
        goToLogin();
      } else if (this.userId == this.uid) {
        this.getProInfo();
      } else {
        mdToast('您不是当前受邀用户，不能开启哟~');
      }
    },
    getProInfo() {
      getProInvitationInfo({
        pid: this.pro_id,
        temp_id: this.temp_id
      }).then(res => {
        if (res.status == 0) {
          this.pro_invitation_info = res.data;
          this.cp_info = res.data.cp_info;
          sessionStorage.setItem('invite_isfirst', 'NO');
          this.isFirst = false;
        } else {
          mdToast(res.message);
        }
      });
    },
    jumpAgreement() {
      const url = "https://m.modian.com/about/about_index/3/2";
      MDRouter.Link(url);
    },
    acceptInvite() {
      if (!this.agreement_status) return mdToast('请同意授权协议');
      AcceptProjectInvitation({
        pro_id: this.pro_id,
        temp_id: this.temp_id
      }).then(res => {
        if (res.status == 0) {
          this.pro_invitation_info.is_join = 1;
          showDialog({
            message: res.message,
            confirmButtonColor: "#00C4A1"
          }).then(() => {
            if (res.data.url) {
              // eslint-disable-next-line
              graft.app({
                functionName: "openController",
                functionParams: res.data.app_url,
                webFun: () => {
                  MDRouter.Link(res.data.url);
                }
              });
            }
          });
        } else {
          mdToast(res.message);
        }
      });
    },
    // 同意协议
    agreement() {
      this.agreement_status = !this.agreement_status;
    },
    back() {
      // eslint-disable-next-line
      graft.app({
        functionName: "closeController",
        functionParams: "",
        callBack: () => {},
        webFun: () => {
          window.history.go(-1);
        }
      });
    }
  }
};